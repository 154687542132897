body {
  background-color: white;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
}

.add-note-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}

.app-container {
  grid-template-columns: 1fr;
}

@media (min-width: 600px) {
  .app-container {
    display: grid;
    grid-template-columns: 10px 1fr;
    gap: 20px;
  }
}

.notes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(250px, auto);
}

.note-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d1d1;
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 80%;
  height: 75%;
  margin: 5px;
  overflow: auto;
}

.notes-header {
  display: flex;
  justify-content: flex-end;
}

.notes-header button {
  font-size: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  max-width: fit-content;
}

h2 {
  margin: 0;
}

.note-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 40%;
  align-self: center;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.note-form input,
.note-form textarea {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 2px;
  font-size: 16px;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

.content-area::placeholder {
  font-family: Arial, Helvetica, sans-serif;
}

.note-form button {
  background-color: #7ce0a2;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.note-form button:hover {
  background-color: #56c681;
  font-weight: bold;
}

.edit-buttons {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
}

.edit-buttons button {
  flex: 1;
}

.edit-buttons button:last-of-type {
  background-color: rgb(245, 116, 116);
  font-weight: 500;
}

.edit-buttons button:last-of-type:hover {
  background-color: rgb(228, 111, 111);
  font-weight: bold;
}